import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/Blue_Iris/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/Blue_Iris/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Blue_Iris.png",
  "social": "/images/Search/P_SearchThumb_Blue_Iris.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Blue-Iris_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Blue Iris' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app Blue Iris for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Blue_Iris/' locationFR='/fr/Software/Windows/Blue_Iris/' crumbLabel="Blue Iris" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h2 {...{
      "id": "blue-iris-v3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#blue-iris-v3",
        "aria-label": "blue iris v3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Blue Iris v3`}</h2>
    <p>{`Use up to 64 cameras, capture JPEG snapshots or movies in standard MP4, AVI, advanced DVR, or Windows Media file formats. Keep an eye on your home, place of business, cars, and valuables; watch your pets or your kids; monitor your nanny, babysitter, or employees. Watch your door for mail, packages or visitors. Use motion detection, audio detection, or capture continuously. Receive alerts via loudspeaker, e-mail or phone. Overlay text and graphics. Use the built-in web server, or post to a website. Push to a Flash or Windows media server.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="http://blueirissoftware.com/" target="_blank" rel="noopener noreferrer">{`BlueIris`}</a>{` we recommend you to download the latest version from `}<a href="http://blueirissoftware.com/download/" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`Start the New Camera Wizard and choose your INSTAR camera model from the drop down menu. Now type in your camera´s IP address, HTTP and RTSP port (in case of an HD model) and your login credentials. For the video path you can choose between 3 different quality settings /11 Highest quality and Bandwidth Requirements, /12 Standard Quality, /13 Good Quality and Lowest Bandwidth Requirements. Reduce the video quality according to the available network bandwidth. Click ok to add your camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/33d1d/BlueIris_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC+UlEQVQoz1WRXUhTYRjHH/BSL6Y2NHWQZAmZBCZeRDoJojBzjPwMBTedrtXcWW5KFEREQRddxHRg2EXah9NS1Aq8UivoKsqLPsycbXK2nXN23nPOztnOdma+MV1S8Hsunosf///DA519g+3E9Q7bjU7jFV1jS1OHoXvwlt5gqWvQ1+ub6vWNF5pb9e3GNsfd5l57XYPuzHmdrqnDctN9+Y4bDhwuyy8uzdxXkJGpUhUcKC6rKCgp23+wrPDQ0TSHjxaVlucVl544dXbgxi2jua+1o6ul3WC64oSsrCwAqK493WMdvH77/vPZxclXbz1zSxNzS575Zc/8cmqdX56YW5xe+OB6NHnt5r3G8w0AUHOyGrKzswFgZOQhxlgUYhyKxOXkVnJ7K4mTynY8pkQ4KRpNyLGkFJEFQcIYT3heAIBWqwVVtgoAhlzDGGNEs0yIRgySJTkejQuIpwM0FaAETuDCXDjEIBZhjMfGn6WSa2rSycMuN8aYClA/f/xcX1unAzTpJ31eHxNiWIZlaRYxKEyzYSqMMR7fk3NycgDA9cCFtzBiEI94nuUFFJEleReeFUJkCDGcJEgiL+7Iz/+Th1xuWZJFUUwqyV2UhKIklEQ8wYU575rXt+4LkSFyM5CSn/yVc3Nz08nbWIpIkhCNiXIsIsviDlIcMSiwGaRIivQHSD+5l6z9V5YlmQrSIi+KO/VEXoxwEVGQ6CBN/trkUgezKMxjjB+PPU3LarUaAEZHRzHGsZ0jE7GEIiu7/E5sRUXZH2TJIEuRoTDFYownp16ma6tUqVcZjd1TUzNvXi98/bL6/dvahte/4fV7132r39a+rnoXPq6+W/nx+dPK4tL76ZlZx+A1AKjV1oJWW1tVVVVRcby8/FjduXprH2G+ZDH1mk095m5Tr8HYZTB2mUymzk5DU3NrXl4+AGRkZABARWUl2O12p2NgwOl0OhyO1Dj6+/ttNhthI+wEcdVutxMEYbEQVuvFtjaNpkij0RQWFu5Tq4+UlPwB4U3tcC/A1VsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f434f5cf0a321f8edccb3ebbacd515a9/e4706/BlueIris_02.avif 230w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/d1af7/BlueIris_02.avif 460w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/7f308/BlueIris_02.avif 920w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/d31ba/BlueIris_02.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f434f5cf0a321f8edccb3ebbacd515a9/a0b58/BlueIris_02.webp 230w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/bc10c/BlueIris_02.webp 460w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/966d8/BlueIris_02.webp 920w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/7ed5b/BlueIris_02.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f434f5cf0a321f8edccb3ebbacd515a9/81c8e/BlueIris_02.png 230w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/08a84/BlueIris_02.png 460w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/c0255/BlueIris_02.png 920w", "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/33d1d/BlueIris_02.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f434f5cf0a321f8edccb3ebbacd515a9/c0255/BlueIris_02.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the network IP camera configuration to add a new camera.`}</p>
    <h4 {...{
      "id": "advanced-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#advanced-settings",
        "aria-label": "advanced settings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Settings`}</h4>
    <p><strong parentName="p">{`General`}</strong></p>
    <p>{`Name your camera and add it to a surveillance group to be able to establish group policies.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/35189b392b3e1a494b4531ee31912450/72372/BlueIris_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADI0lEQVQoz23QeUiTcRgH8AcUpcOcpLV5LDuoTIU1reXeaUbZampMI2cpZZrL3Pu+c853x5s1j4oK7SCooOifIoiIouOfDqKDUJtaUVLQRYdz27v3nbXLbb6/iCAwguffD9/v94H6FnpHm62WtG6vJ2tb2ne20g3UvoodTaryTWUVlWXqysotVeqaumpjd1WjXqkqK16vLK3Y2mw7rTt4GuZkZE5Lmhs7a3bMTMGMuWLREmlqVn5a1gpxjuzPzctdJc6RiZZKi5Tqju4jBmtHvZbQ1NQ14m0gnpeZnpYhTs9MTEzRanX9gyNP+14OPH/18LH9Sd/Lh0/t9x8NPBt4/eDRc/vgSAtOAkDCjOkAoJDLQac10saO3p7zZ45eune7DyHEsF77R87FcBzLelh23OvlPMw4x/I8bzRZACAuLg4AMAyDmvItDZq6Y52HzbsNt67eRQiNOpmPzMQPf9Dn9zEMGwyF3AzjYVmEkMlK/8VyuRwwWVFxgVJTWrVu9cZzZy8ghN69//D5yxf/z3Ee8T6fz+/3c97x76MOnkcm694peE1hsapkc5F8rWIVdurkKZ5HwUB4IhgJBSdCgVAkHEEI8TyKRqMIIcoyNTlPki/Jzi/fUJK3XNKxv+vmldtXzl+/eObyyQPH9+vpO9duhCNhl8vtdrn+g1VrFMtzVkolBYkJSd1dh2x017qiks2l1aUlFTIJ1nv0BEIo4PcHg4F/NmNyDLKXSUXC+eKMZSnJ6Z22g3qyTSpRqMu2bVJVF6xUHuvpGRy09/f1Dw8NOxxjlMU6BS9cmC0QJC9ZnJs4S9jefsDhGHvz5q2X4zwe1ul0Ox1jnz99evHi1fDQ0Lev3ymzdUrtJIEAAGJjYwFA27gbIRQKhKLhST4yORGOjnqDrC/kdrpYDxeNTJotv78dHx8PAIWKQsDWbpDKsNw8WY50hVpTS7fbTGaaMlspk7WVsjQbKKKVMlBmoqV1l7ZJnLkAAGJiYgBAIs2DBsqmM9uajBbCYjPQnXqSxHEdjuMETpAEoSdJPUEQTXuacb1Go0lLTRWJREKhMHlOStaiRb8AI3SEuJ2ig7sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35189b392b3e1a494b4531ee31912450/e4706/BlueIris_03.avif 230w", "/en/static/35189b392b3e1a494b4531ee31912450/d1af7/BlueIris_03.avif 460w", "/en/static/35189b392b3e1a494b4531ee31912450/7f308/BlueIris_03.avif 920w", "/en/static/35189b392b3e1a494b4531ee31912450/433d4/BlueIris_03.avif 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/35189b392b3e1a494b4531ee31912450/a0b58/BlueIris_03.webp 230w", "/en/static/35189b392b3e1a494b4531ee31912450/bc10c/BlueIris_03.webp 460w", "/en/static/35189b392b3e1a494b4531ee31912450/966d8/BlueIris_03.webp 920w", "/en/static/35189b392b3e1a494b4531ee31912450/27033/BlueIris_03.webp 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35189b392b3e1a494b4531ee31912450/81c8e/BlueIris_03.png 230w", "/en/static/35189b392b3e1a494b4531ee31912450/08a84/BlueIris_03.png 460w", "/en/static/35189b392b3e1a494b4531ee31912450/c0255/BlueIris_03.png 920w", "/en/static/35189b392b3e1a494b4531ee31912450/72372/BlueIris_03.png 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/35189b392b3e1a494b4531ee31912450/c0255/BlueIris_03.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`General settings allow you to name your camera and assign it to a group.`}</p>
    <p><strong parentName="p">{`Motion Detection`}</strong></p>
    <p>{`The `}<strong parentName="p">{`Motion/Trigger`}</strong>{` menu allows you to enable the software motion detection for your camera and fine tune its sensitivity.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7503a892244a2bf58eac3387ab6574e/e6c84/BlueIris_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFElEQVQoz12QbUgTcRzHf6Ap9KCjHG61llaQOjPbtLVNzbAHs1Us8aGMUsqVzttmzj3oaNoqip4IKoLAl70IKuhdERE11MRKDa0waJqObbbtbne3u6u7/mFvSr9833748v1Ak9V1wuZpwFzHm8zHrO7GdtdJm9tw4kzVwUN6Q7XeUH24ptbQ0FR31ltjtFbqD5TvrdxvONLqudN28S5I5NlLV2ampIuXpK1aLsmS5qhW56qkOSqZoliWVyxTFMs3q+X5askmZemeQz3eK+2uniYjVtvQ2GyygXxdlmzNWvnarHSRuLm51Tc46hsYHRr+/No3Mjg87ns79uLVkG9w9OWb4XfvJywmDADSli0FAJ1GA9hpm9vWe/NG372rD54/7WcT9OS3r2+/+D4FRkgKJ6JRMk7g0UhwdjZB0zZnFwCkpKQAgFarhaP6mlP1jTd6LzuM1qcPnyVYJhAOT0f9AXImTpKJBBMOhwcGBsfGPiKE7K5/sEajAZ26rFyzt05fu2vHvvv3+iiKikai6Df6yfwkSRIh9PjRk4ryiuvXriOEOuyOBfDO0vKq3dVl2oqS7drbt24jhAiCSDAMyzIcxwmCEJgN9Pf3T0yMI4Q6nQuXVYVFhYqiA/t2Kwu3eHsv8gI/MzMzNzfHzPNsOBQa+TAyOTnp/+ZHCDm6uhfAVTtLtuZvUxVq0laILngvsywbCARoOsHzAstxHMfG8Fg8TlIUPf/5f2EaLSjylFLJerk8T5whO997iePYqSl/KBTEcZyiaIZh8VgsEon8+DG3SNg8vHGDQiQS52wqSE+TuLu9CKFYLBaPxyma4jiW53n+1y9BEILBIC8Ii22L0kUAkJycDADGZiMSEB4lSIJkaIYkE/4wHorGQ8HQtP87QZB25/zn1NRUACjRlYCuolKp1hUUqfOV2wx1x9znepxd7r/t7nR0mTvsFpu9vdNh7eg802KSZ2UDQFJSEgBs2aqEU3aPyelp6XCZnZ727vMWi7ntbzAMM2OY1WKxYGZzS6upzVJfV7taKs2USCSZmRnijNwNG/8AlKmb3fD8mHUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7503a892244a2bf58eac3387ab6574e/e4706/BlueIris_04.avif 230w", "/en/static/d7503a892244a2bf58eac3387ab6574e/d1af7/BlueIris_04.avif 460w", "/en/static/d7503a892244a2bf58eac3387ab6574e/7f308/BlueIris_04.avif 920w", "/en/static/d7503a892244a2bf58eac3387ab6574e/ffaca/BlueIris_04.avif 1148w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7503a892244a2bf58eac3387ab6574e/a0b58/BlueIris_04.webp 230w", "/en/static/d7503a892244a2bf58eac3387ab6574e/bc10c/BlueIris_04.webp 460w", "/en/static/d7503a892244a2bf58eac3387ab6574e/966d8/BlueIris_04.webp 920w", "/en/static/d7503a892244a2bf58eac3387ab6574e/d8c59/BlueIris_04.webp 1148w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7503a892244a2bf58eac3387ab6574e/81c8e/BlueIris_04.png 230w", "/en/static/d7503a892244a2bf58eac3387ab6574e/08a84/BlueIris_04.png 460w", "/en/static/d7503a892244a2bf58eac3387ab6574e/c0255/BlueIris_04.png 920w", "/en/static/d7503a892244a2bf58eac3387ab6574e/e6c84/BlueIris_04.png 1148w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7503a892244a2bf58eac3387ab6574e/c0255/BlueIris_04.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Enable and adjust the motion detection settings for your camera.`}</p>
    <p><strong parentName="p">{`Video Recording`}</strong></p>
    <p>{`The Record menu allows you to enable and adjust the video recording for your camera and fine tune the storage requirements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/4dba2/BlueIris_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADBElEQVQoz23QW0hTcRwH8F9oChFTUWmbuS5UVmqZW5qmm0TmpRUq5SqjppZoO9vUue1sszmxoDs9REK9Ro/1FPVUaFmRZRAiFUbU5q6e7XjuO7j9w4pg0Jfv6we+fKGz33F2aOS0yXmmy3S6f1g/6Oy2XmzV9zYfOaptbdO2tLUdb2/p0J+wXGo/b25o1tYdatC2nrrgGccu3wWpYvOa3HUZ2fmrJblrpRvk25XyHUrZDmVhSYWipPJviyvkReXqxlbP2NUBh6ezB9N16HswKxQqNshkBesLCiWSXH1nz8TUzOTUzNt3cy8mPkxMfXw+Of18cvrV9NyzyZnp97NmgxEAsiVrVwFoatXQd27AZfHcuHZ//PqDp49e8iwz/33+3depWe+HYHDB7/UJHBMiyK8/FjiWHbThAJCRkQEAarUaTmqPdevO3vRcwXsHHz98kkgmv3ybf/Pp9WffXCAQiMXIKEmGQ8EFnxchZMOd/3BtbS1UV6rrqhp0h4/Xa5rG79zjeD7g9xNhIhwIMzQjCEIkEonFYuFIGCFkxR0puK5G01Tfpq4+WFNZffvWbY7nCYKgaIrl2EQiERdFmqZJcikWjSKEhux4ClaWqXYXq7SN9ao9ZW6XByWTHMfF43FRFJeXlxOJhCiKwu/8BzcfqNlTWqEqq8qS5Iy4xwQhHgyFCILgeUEURZIkl6gliqJYll3BtlRcWqyUSjcpFMV5ueuHh0d5QfB6fwaDQZ7nFxeJRYKgaYamaIZhEEIWmz0Fb91ampWVV7StNEsitdncCKFolGBWwsYFIc6xkVAoEo74/YGVw+yph+Xk5ABAeno6AHTpu1ASMRTD0hzPcDGKmw3S30Ok76d3wefnOOHP7MzMTACo02hg/8FG5b79u1SVJeUVLe0druERG+6yO1w47hqyOw1Wl8nqGLTh5gHL+Z5excZNAJCWlgYAyr17odvqNthH+iy40e4ecI6azWYMM2AYZsSMJqOx32Q0Y5ipt8+AmXXtOrlcLpPJpFJpXn7+zi1bfgFSiKATa8qoaAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/658a0b1a63cb5893e61a7e5c574aaa02/e4706/BlueIris_05.avif 230w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/d1af7/BlueIris_05.avif 460w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/7f308/BlueIris_05.avif 920w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/3c69e/BlueIris_05.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/658a0b1a63cb5893e61a7e5c574aaa02/a0b58/BlueIris_05.webp 230w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/bc10c/BlueIris_05.webp 460w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/966d8/BlueIris_05.webp 920w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/ee998/BlueIris_05.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/658a0b1a63cb5893e61a7e5c574aaa02/81c8e/BlueIris_05.png 230w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/08a84/BlueIris_05.png 460w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/c0255/BlueIris_05.png 920w", "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/4dba2/BlueIris_05.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/658a0b1a63cb5893e61a7e5c574aaa02/c0255/BlueIris_05.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Adjust the video recording settings for your camera.`}</p>
    <p><strong parentName="p">{`Webcast`}</strong></p>
    <p>{`BlueIris built-in web server enables you to bring your cameras with you anywhere. Remotely connect to your workstation from anywhere in the world, from any web enabled device. Just activate the web server and forward a port to your windows computer and have access to all your cameras.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/880867a73545d1e7f50fbb7f09973039/33d1d/BlueIris_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC5klEQVQoz23RW0hTcRwH8D9oChZO0dGcuWnYBS/lZTkv80JoK1ulUq5Q8jKx2c7OnHNtunTrBoUFQaBEr9FrT9FLFDExlITKl+ixIo+b45zjuex//jtn/3C+ZPjj+/SDDz9+vx8YGp8amAz2k9PXh8n+8duDE9M270z3oL3zwkVLd4+lq6fnSm9X3+BVz/3e0XHzeUvbGbOl+9rN0LzjwTzQ6A5n5R3MyFHvy847oNFrj9cWlhkKy07pKow70VfW6yvrtcdrm82XQvceuadCQ6OEtW9wlPACvb64sLBIV1Sco1IP2+xLy9/Cn74srax9CH9eXP72Ibz67uPK0sra+/Dq17UfLocTAJC9PwsA0NxkAmMj7oAn9HjuxcLcy7evF1mGjqyvb0Yi0Y2NCLVOxzY5luEYht9iMMaTvikAQEZGxjZubgbXLJdt1oEnoYd+u/v1qzcJRYEQ8jwfiUZpho1DiWEYiqJ+/vqFMfb+g00mE2g0trQ1mK3nr3S0nlt49hxCyLKsrCgsu8VxvASlVGcrFoulJvt34TZT67mOnpbGdpOx8emTp8lkkqZphNAWx4miKEkSx/OCKAo8jzH23NqNa6sMJ8sNlrMdhuqq2UAIY4wQUmRZTiREUUQISZIkCEI8Ht8Dd542VVfWGaoaVNm5wdm7GGOGYQRBSGzzVMlyQpahJO2BK8pqNZoSna5cnX/odiAEIaRpmqIoURRlWUYpjhCCEG7v/B8+UlqRo8o/drRSpdJ4vTNKUuF4jhcESZJgXFQQUhJISt1tj8m5ObkAgPT0dACAbdiGMeZYXuQhFCDLxb9Hhd8xnvpDbWxEEZJ3XpWZmQkAaG1pAU3tZ2vqm04YjBU1dV29fYGZoH8q4Etl0hdwTE6TXv/ELR/p9ozcGNMVlwAA0tLSAAA1BgOweWcdvqDd43f6g+7pOy4XSRAOgiCchJN0OsdJ0kU4SfuYg3BZrVatVltQUKDRaPLV6rLS0r9wraW7CojHagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/880867a73545d1e7f50fbb7f09973039/e4706/BlueIris_06.avif 230w", "/en/static/880867a73545d1e7f50fbb7f09973039/d1af7/BlueIris_06.avif 460w", "/en/static/880867a73545d1e7f50fbb7f09973039/7f308/BlueIris_06.avif 920w", "/en/static/880867a73545d1e7f50fbb7f09973039/d31ba/BlueIris_06.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/880867a73545d1e7f50fbb7f09973039/a0b58/BlueIris_06.webp 230w", "/en/static/880867a73545d1e7f50fbb7f09973039/bc10c/BlueIris_06.webp 460w", "/en/static/880867a73545d1e7f50fbb7f09973039/966d8/BlueIris_06.webp 920w", "/en/static/880867a73545d1e7f50fbb7f09973039/7ed5b/BlueIris_06.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/880867a73545d1e7f50fbb7f09973039/81c8e/BlueIris_06.png 230w", "/en/static/880867a73545d1e7f50fbb7f09973039/08a84/BlueIris_06.png 460w", "/en/static/880867a73545d1e7f50fbb7f09973039/c0255/BlueIris_06.png 920w", "/en/static/880867a73545d1e7f50fbb7f09973039/33d1d/BlueIris_06.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/880867a73545d1e7f50fbb7f09973039/c0255/BlueIris_06.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate the webserver to stream your cameras to the internet - don´t forget to forward the port to your workstation.`}</p>
    <p><strong parentName="p">{`Pan, Tilt and Zoom`}</strong></p>
    <p>{`Please make sure to activate the correct PTZ profile for your camera from the drop down menu to be able to use the camera´s PTZ functions.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b7b35208674896b41cd2271ce4204b2b/4dba2/BlueIris_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7UlEQVQoz23RW0hTARzH8X9oCuFM89LZdGpplDpD26Z52yRS07S8kDOzvCKaZze3ubnp1IrAAvEhknrtocfoJSJKzSS0rOild2UXt7lz2e1c5nYCJSLy9/7h+/CDfs1Er36mW2Xu6Vff1k71jVkGDda23pHGa9ebW9ubW9vbOxRt3X03dQ86hjT1V5tq6uqb2rpGZxeVDxcByTp9LAWJS0o/ejwl4WQO/6xYkC8R5EuyRGXCwtLMAqmwsFRYWMo/J5bVt8zcn9NOzPQNoR23eodQAwiF2QjCzxBk8ngn7vQMrqx9W1r9urL2/f3KxoePX5Y+bS6vba5+/vFueWN9/afqLgoASYkJRwDkMhkMD2jMY9OP5549ffTi7as1HNt12m27brfL6SQJPBjw+X1EJBwmMC8XjerGTQAQFxcHADKZDLqabgx29s7Pzk2M6F6/fBNimF2v12azbW1t4zhBEiRJ+kifn6JpjuP0xr+4uroaKstk8vK6zqaO2prGxSfPoxwXCoUIgnA4HN4/IwkCx/FDcE2VvKG2XVZRW1lWsTC/wDCsy+2maWZvb28vHKYPxrKEP3AIFpdIikXS5it10gsl1skZmqbsDgdBkn6fn2FZhg1zHOcOsL/sGPU/brxUVSySSkoqkhKTp633GIbxejGWZcPhMEmSIYqKRCI0TQWDwUPKRSIJHzmdnSVKT82csswGgoHt7W232+PxeLxejGEYmmZsNvuOy7WPJ/7BebkiHi/5TK6Il5Cu109GoxEMw3AMx3Gcog+yDMdxLMseUk5OSgaA2NhYAOjv6+eiXMAXDAUoKkj7CL/TseNx79rtjh2ni6IZ/f7P8fHxAFAjl0Pl5QZxeeV5ycUicWmLotsyOT1ushhNlnGT2WA0a3R6rX5cazCqNLrBoeGsnFMAEBMTAwBiqRQGDNZRo3VkzKQ0WbXmWbVajaKjKIoqUaVKqVSr1GqlUjU8MoqqFQpFhkDAR/gIgqSmpRXk5f0GfGGilADt7tAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7b35208674896b41cd2271ce4204b2b/e4706/BlueIris_08.avif 230w", "/en/static/b7b35208674896b41cd2271ce4204b2b/d1af7/BlueIris_08.avif 460w", "/en/static/b7b35208674896b41cd2271ce4204b2b/7f308/BlueIris_08.avif 920w", "/en/static/b7b35208674896b41cd2271ce4204b2b/3c69e/BlueIris_08.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b7b35208674896b41cd2271ce4204b2b/a0b58/BlueIris_08.webp 230w", "/en/static/b7b35208674896b41cd2271ce4204b2b/bc10c/BlueIris_08.webp 460w", "/en/static/b7b35208674896b41cd2271ce4204b2b/966d8/BlueIris_08.webp 920w", "/en/static/b7b35208674896b41cd2271ce4204b2b/ee998/BlueIris_08.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7b35208674896b41cd2271ce4204b2b/81c8e/BlueIris_08.png 230w", "/en/static/b7b35208674896b41cd2271ce4204b2b/08a84/BlueIris_08.png 460w", "/en/static/b7b35208674896b41cd2271ce4204b2b/c0255/BlueIris_08.png 920w", "/en/static/b7b35208674896b41cd2271ce4204b2b/4dba2/BlueIris_08.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b7b35208674896b41cd2271ce4204b2b/c0255/BlueIris_08.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Activate the correct PTZ profile for your camera.`}</p>
    <p><strong parentName="p">{`Schedule`}</strong></p>
    <p>{`Schedule your camera operation - it is recommended to establish global or group policies and only use the individual camera schedule to cover deviations from the main schedule.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/33d1d/BlueIris_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADKklEQVQoz13QeUiTcQDG8R9oCh22DmsznRqSorPMrTSdR4dZHpVKk1LJaYe2992au95t6ra0oV0EQoLYIRGVEFjRYZChTRO1pmVERWWRby7frde9m73bnL+I/ogFz78fHviCCqm6XK4vE2sOVUjKpHXCGu1hRX1heXXunr35hUV5BYVFAkFBqfCArFFw9ER2bl7mzuy8woMiw0XE2AqY7LULl68OWBq8IGjFYmY4K5obEsNjxXBDYzeFxvLYnKTw+GR2fBIrhpuevU/f0CxV64VHEUFJ+RFEAcLDI9asCWOHRTAYq4TCY6bno30D5sGRN/1Dr02Dr570Dvf0DfcNmHuejQwNj6PVIgBA0KKFAIC0VD4QHZFpZPpzZ9tbz1zv7hogSdv3yUnKTpKk1emw22wEMW2ZdVKkjYDz8zIlBgAICAj4g9PSQEn+/sri8vOGJlWV9O6thy6vlyAI0kqSNjtps9NO1yxFe91er2cOQihX/cN8Ph+kJmVkbskuzhXsyNjd1nrJ43ZPW4gXE0MPxruevH3wePzOo/F73a/v3B+9PTvnVKlrffDWtMycrKL0lO385JSWCy1uj+vzx6/XRtv1fSeMJrXRhBlNqlMmTX1PjWUW12h0PpibwEuI4+XvzuImbDDoGiCExJT15tiVk73y0/11Tc80TSZts0l7skducX7/H+ds42/kbOZuTAlawmgwGCGEP63k0/fdN8yXO80dnWMdN81XO8c6rg21OeYoTF3ngzlxXBYzks2OC14Rqtc1ul0uHMc9Lo9jxvmLot9NfO5/1/sSH3yFmyGECpXaB0dFcRiMldHr4pcGMTFMR9O0ZWqKsBKWHxaKomgXPQ+9tJvGp/B5COVK39rLGMsAAP7+/gCAyopK6IUzP2fsJOWwO6y2mQ+T0xYrhX/DJz59oeyOv8+BgYEAgIz0dJC6Y1dicup6XhIncfM+QWltnR5T12KaP1OqtWK5SiJX1igxqVxxrOp4WEQkAMDPzw8AkMjjgUpFvUilq5JhKKaTag0SsViEiBAEQREERVGJWCxBUbSq+rhIXFwsCAkJYbFYTCZzZXBwbFTUb6oviZnfkky6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0302de4de80a8e554c4ca89b1ef8d7da/e4706/BlueIris_09.avif 230w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/d1af7/BlueIris_09.avif 460w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/7f308/BlueIris_09.avif 920w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/d31ba/BlueIris_09.avif 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0302de4de80a8e554c4ca89b1ef8d7da/a0b58/BlueIris_09.webp 230w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/bc10c/BlueIris_09.webp 460w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/966d8/BlueIris_09.webp 920w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/7ed5b/BlueIris_09.webp 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0302de4de80a8e554c4ca89b1ef8d7da/81c8e/BlueIris_09.png 230w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/08a84/BlueIris_09.png 460w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/c0255/BlueIris_09.png 920w", "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/33d1d/BlueIris_09.png 1150w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0302de4de80a8e554c4ca89b1ef8d7da/c0255/BlueIris_09.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use the camera schedule to make adjustments to the operations of the specific camera - use group policies or global settings as your main schedule.`}</p>
    <p><strong parentName="p">{`Alerts`}</strong></p>
    <p>{`Define the actions to be taken if an alarm event is triggered.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9fe78d752b316307927d0f55843a7577/72372/BlueIris_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADBUlEQVQoz23QX0xScRQH8LPptPWHcBWBCtqfZYUuBP8kFytrZmlW6pKca/l/GveChAgCBaW1atXa3HJr9dZDr63VS1u1/JOpszTdesicpQKXC8jlIghcfo2eavM8n8++53yhSWtq6LJdUpsuN2kuaa816swt3derGzrKz56rrKo5U1VdXausqm+s0/XVtnWWVZw5drKsoqpOZXuM3x4AnnDXhhReImdbwmbuJp5o575cfpZUsF8mFOeni/OF4oKMnEJRdoEgS1p88py19662x9bYRtTWN7SquiAjIzMtTSgSZnK5vOaWjk8TM6PjM2Pj3weHpz4MTb4bnHg9NP3+8+zHkcmJiVn1FRwAOJs2AoBCLgdVm86ksz24/3Tg3vO3r0YRQk43+WV+bHph3O0mHUvLTpebJF0LP+eCwVV9jxkAkpKSAADDMKivvNCsbHh4446xXfvyxZsIG/0+92Nkevjr3OSy3e50kJSL8ni8JEkihLr/wXK5HLDCo8eKypQVtaVHTz8ZeMayrM+7EgqsBehVhgmsrYVpPxOJRoPBIEJIbzT9h0uKS8pLa47ITygOY/2P+lmWdVGUi6I8Xk84HI5EIn6/n2GYUCj0N/l/LJPkScR5ladLZZJDN219CCGKorxer8fj8fnoWIyNt+BwTE99W+fs8uOK3OwCWW4RZwu3r/cOQmhpcdHxd2iajsViCKHFX79HR+JdGkz/FCbHIPugVMDfJRKJd2xLt1lvxfHSot3hoNxuhBDLxvGvhYWhweF18J49Yi53e9a+nK0cvsl8EyG0HMd2iqICASYSDiOEaJ9vfn5+nZ9TuFwASExMBIDWltb46oqfoQNBZtXPBH+SvmWKdtqdlMsdDke7jfHk5ORkAChWFAN24pS0EMuRFWZL888r6y0Wq7HHYjRZjD0WvcFEdBk1XYaregPRebWlrUOUuRsAEhISAEAilUGz3qoyWNt1RsJg1ZpvaDQaHFfhOE7ghJogNGq1hsDV7R0qXHNRqUxNTRMIBHw+fztvx4G9e/8AAOGf1VC1ZykAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fe78d752b316307927d0f55843a7577/e4706/BlueIris_10.avif 230w", "/en/static/9fe78d752b316307927d0f55843a7577/d1af7/BlueIris_10.avif 460w", "/en/static/9fe78d752b316307927d0f55843a7577/7f308/BlueIris_10.avif 920w", "/en/static/9fe78d752b316307927d0f55843a7577/433d4/BlueIris_10.avif 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9fe78d752b316307927d0f55843a7577/a0b58/BlueIris_10.webp 230w", "/en/static/9fe78d752b316307927d0f55843a7577/bc10c/BlueIris_10.webp 460w", "/en/static/9fe78d752b316307927d0f55843a7577/966d8/BlueIris_10.webp 920w", "/en/static/9fe78d752b316307927d0f55843a7577/27033/BlueIris_10.webp 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9fe78d752b316307927d0f55843a7577/81c8e/BlueIris_10.png 230w", "/en/static/9fe78d752b316307927d0f55843a7577/08a84/BlueIris_10.png 460w", "/en/static/9fe78d752b316307927d0f55843a7577/c0255/BlueIris_10.png 920w", "/en/static/9fe78d752b316307927d0f55843a7577/72372/BlueIris_10.png 1149w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9fe78d752b316307927d0f55843a7577/c0255/BlueIris_10.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define the actions to be taken if an alarm event is triggered. Or use the alarm event to trigger an external device like an Arduino board.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05dcb059e51fd291116a9d08d2ca06bd/4dba2/BlueIris_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVQoz2WQXUhTcRiH331g6rQEHawLNUOjBVYiDgo2F5YSZpYhTmctNb/mpnObrn2Ympu7qO4koTJL6yLopugy6EoQ7BOpmWWwoe7sbOkmuOPZ+fjHcSI2H363z/u+vxeaDFaNefCGwd7camzqH26zjmgdo6qO3iu1qpp6dY1KXX9dU9fcqbE/qO80l1+sVJZXXLraoB0a17kegiTnaGqmhC/KAGHKf0kS7eRAGi85DfhJBYVFjmF3r3WoqU1Xp77ZpuuD3Nw8cZZYrigzWO6arE6j1WmyucwOt9nhNtlcXOyjRpvLYnNeq6rOOHQwPU2ULkrhAZTKFaBv7+vvdrx98x7DQn5/0OtdXlryLi9jGBbyeldWVgI+36ofC25GyYnJKQDg8/kCgQAAFAoFNFbVNlar3r16jQUC/tVVHMfxQCCI46FgcO3vWji8HgqFIpEwQujxxOReWS6Xg/zsOdlJ+dj9MYTQRmSDJEmKohiaIWMxFu1A0zRCaPL5dKJ8XlmmOFPhGrnHMEw4HCEIgmGYuMOybIyMRTej4fA6J0+9SJRlxbJjeVL3iBMhxGzDsrsrOSiK8vl8CKFn0y8T5cvlyoIj0qEB1+55u7DbYVh2Mxrl5P2bTxXKUlMyjUYbsbWFYRgexCORDYIgKJrmyrMc8aFP93eWSouEwuTblgGEEEEQZIyMQ9OcvfdhE8+mEmVxlhgAWppb4p333kyxiGZZlmFoipPHHz0BAIFAIBQKAUBZWgqKC5XHC0/rTZbfXmx+4Y/H88vzY3HBszj//eeH2S8zH7/NzMzOzn2e+/TV7hgEAB6Px+fzAaC4pARazHe0lsFbepNGa2g19Ov0+q7t6PTdPXG6e7raO7QduoYGdU52tkQikRyWZInFJ/Lz/wGI5bkRP3mbUQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05dcb059e51fd291116a9d08d2ca06bd/e4706/BlueIris_11.avif 230w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/d1af7/BlueIris_11.avif 460w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/7f308/BlueIris_11.avif 920w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/3c69e/BlueIris_11.avif 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05dcb059e51fd291116a9d08d2ca06bd/a0b58/BlueIris_11.webp 230w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/bc10c/BlueIris_11.webp 460w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/966d8/BlueIris_11.webp 920w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/ee998/BlueIris_11.webp 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05dcb059e51fd291116a9d08d2ca06bd/81c8e/BlueIris_11.png 230w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/08a84/BlueIris_11.png 460w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/c0255/BlueIris_11.png 920w", "/en/static/05dcb059e51fd291116a9d08d2ca06bd/4dba2/BlueIris_11.png 1151w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05dcb059e51fd291116a9d08d2ca06bd/c0255/BlueIris_11.png",
              "alt": "Blue Iris for your INSTAR IP Camera",
              "title": "Blue Iris for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define the actions to be taken if an alarm event is triggered.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      